import React, { FC, useEffect, useRef } from 'react';
import { Category } from '../../types/Category';
import { Box, useTheme } from '@mui/joy';
import Column from '../Column';

export interface BoardProps {
	categories: Record<string, Category>;
}

export type Taken = Record<string, Record<string, boolean>>;

const getInitialState = (categories: Record<string, Category>): Taken => {
	try {
		const fromStorage = localStorage.getItem('taken3');

		if (fromStorage) {
			return JSON.parse(fromStorage);
		} else {
			return Object.fromEntries(
				Object.keys(categories).map((category) => [
					category,
					Object.fromEntries(
						Object.keys(categories[category]).map((level) => [level, false])
					),
				])
			);
		}
	} catch (e) {
		return Object.fromEntries(
			Object.keys(categories).map((category) => [
				category,
				Object.fromEntries(
					Object.keys(categories[category]).map((level) => [level, false])
				),
			])
		);
	}
};

const Board: FC<BoardProps> = ({ categories }) => {
	const { palette } = useTheme();

	const [taken, setTaken] = React.useState<Taken>(() => {
		return getInitialState(categories);
	});

	useEffect(() => {
		localStorage.setItem('taken3', JSON.stringify(taken));
	}, [taken]);

	// ctrl + x should reset the taken questions
	useEffect(() => {
		const handler = (e: KeyboardEvent) => {
			if (e.ctrlKey && e.key === 'x') {
				setTaken(
					Object.fromEntries(
						Object.keys(categories).map((category) => [
							category,
							Object.fromEntries(
								Object.keys(categories[category]).map((level) => [level, false])
							),
						])
					)
				);
			}
		};

		window.addEventListener('keydown', handler);

		return () => {
			window.removeEventListener('keydown', handler);
		};
	}, [categories]);

	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'stretch',
				background: palette.neutral[800],
				p: 2,
				gap: 2,
				overflow: 'hidden',
			}}
		>
			{Object.entries(categories).map(([key, category]) => {
				return (
					<Column
						key={key}
						label={key}
						category={category}
						taken={taken}
						setTaken={setTaken}
					/>
				);
			})}
		</Box>
	);
};

export default Board;
