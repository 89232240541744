import React, { FC } from 'react';
import { Question } from '../../types/Question';
import { Box, useTheme } from '@mui/joy';
import { Level } from '../../types/Level';
import QuestionPop from '../QuestionPop';

export interface QuestionCellProps {
	category: string;
	level: Level;
	question: Question;
	taken: boolean;
	setTaken: () => void;
}

const QuestionCell: FC<QuestionCellProps> = ({
	category,
	level,
	question,
	taken,
	setTaken,
}) => {
	const { palette, radius } = useTheme();
	return (
		<QuestionPop>
			{({ onClick }) => (
				<Box
					onClick={() => {
						onClick(question, category, level);
						setTaken();
					}}
					sx={{
						borderRadius: radius.sm,
						flex: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: palette.primary[500],
						color: 'yellow',
						fontWeight: 'bold',
						fontSize: '2rem',
						cursor: 'pointer',
						opacity: taken ? 0.15 : 1,
					}}
				>
					${level}
				</Box>
			)}
		</QuestionPop>
	);
};

export default QuestionCell;
