import { Category } from '../../types/Category';

export const categories: Record<string, Category> = {
	'Book of Mormon': {
		'100': {
			question: 'This person retrieved the brass plates from Laban.',
			answer: 'Who is Nephi?',
			verse: '1 Nephi 4:24',
		},
		'200': {
			question:
				'Nephi says that he was "born of goodly parents". Who are they?',
			answer: 'Who are Lehi and Sariah?',
			verse: '1 Nephi 1:1',
		},
		'300': {
			question:
				'This king of the Lamanites converted to Christianity after being struck down by the Spirit.',
			answer: 'Who is King Lamoni?',
			verse: 'Alma 19:13',
		},
		'400': {
			question:
				'In this book and chapter, you can find the story of the brother of Jared seeing the finger of the Lord.',
			answer: 'What is Ether 3?',
			verse: 'Ether 3:14',
		},
		'500': {
			question:
				'These were the last two Nephites mentioned in the Book of Mormon.',
			answer: 'Who are Moroni and Mormon?',
			verse: 'Moroni 10:32',
		},
	},
	'Come Follow Me (This Week)': {
		'100': {
			question: 'In Alma 30, this person is called "Anti-Christ".',
			answer: 'Who is Korihor?',
			verse: 'Alma 30:6',
		},
		'200': {
			question: 'Alma says this has a more powerful effect than the sword.',
			answer: 'What is the word of God?',
			verse: 'Alma 31:5',
		},
		'300': {
			question: 'Alma silenced Korihor through this means.',
			answer: 'What is the power of God, making him mute?',
			verse: 'Alma 30:39-50',
		},
		'400': {
			question: 'In Alma 30:44, Alma says this testifies of God.',
			answer: 'What is all things testify of God?',
			verse: 'Alma 30:44',
		},
		'500': {
			question:
				'This was the ultimate fate of Korihor after he was struck mute.',
			answer: 'What is being isolated & hungry? (and trampled to death)',
			verse: 'Alma 30:59',
		},
	},
	'Church History': {
		'100': {
			question: 'This location is where the First Vision took place.',
			answer: 'What is the Sacred Grove?',
			verse: 'Joseph Smith—History 1:15-20',
		},
		'200': {
			question: 'This is the name of the first missionary to England.',
			answer: 'Who is Heber C. Kimball?',
			verse: 'Doctrine and Covenants 112:12-13',
		},
		'300': {
			question: 'The first person to be baptized in this dispensation.',
			answer: 'Who is Oliver Cowdery?',
			verse: 'Joseph Smith—History 1:68-72',
		},
		'400': {
			question:
				"This city became the headquarters of the Church after the Saints' expulsion from Missouri.",
			answer: 'What is Nauvoo, Illinois?',
			verse: 'Doctrine and Covenants 124',
		},
		'500': {
			question:
				'This was the name of the first temple built in this dispensation.',
			answer: 'What is the Kirtland Temple?',
			verse: 'Doctrine and Covenants 109:12-13',
		},
	},
	'Modern Prophets': {
		'100': {
			question:
				'This prophet introduced the "Family: A Proclamation to the World".',
			answer: 'Who is Gordon B. Hinckley?',
			verse: 'The Family: A Proclamation to the World, 1995',
		},
		'200': {
			question: 'This prophet emphasized "Stand in Holy Places".',
			answer: 'Who is President Thomas S. Monson?',
			verse: 'Thomas S. Monson, "Stand in Holy Places," October 2011',
		},
		'300': {
			question:
				'This prophet emphasized the importance of "ministering" as a form of Christlike service.',
			answer: 'Who is President Russell M. Nelson?',
			verse:
				'Russell M. Nelson, "Ministering with the Power and Authority of God," April 2018',
		},
		'400': {
			question:
				'This prophet taught "Let virtue garnish thy thoughts unceasingly".',
			answer: 'Who is President Gordon B. Hinckley?',
			verse: 'Gordon B. Hinckley, "Stand a Little Taller," April 2001',
		},
		'500': {
			question:
				'This prophet encouraged members to "Hear Him" in their personal revelation.',
			answer: 'Who is President Russell M. Nelson?',
			verse: 'Russell M. Nelson, "Hear Him," April 2020',
		},
	},
	Random: {
		'100': {
			question: 'What was the first temple built outside of the United States?',
			answer: 'What is the Cardston Alberta Temple?',
			verse: '',
		},
		'200': {
			question: 'This is the largest book in the Book of Mormon by chapters.',
			answer: 'What is Alma?',
			verse: '',
		},
		'300': {
			question:
				'This member of our bishopric has David as a middle name, but only on the records of the Church.',
			answer: 'Who is Brother Borg?',
			verse: '',
		},
		'400': {
			question:
				'This apostle saw the risen Lord on the road to Damascus and later became one of the most influential missionaries.',
			answer: 'Who is Paul?',
			verse: 'Acts 9:1-19',
		},
		'500': {
			question:
				'This location is where the LDS Church was officially organized.',
			answer: 'What is Fayette, New York?',
			verse: 'Doctrine and Covenants 20:1',
		},
	},
	'Life and Teachings of Jesus': {
		'100': {
			question: 'This is the name of the town where Jesus was born.',
			answer: 'What is Bethlehem?',
			verse: 'Matthew 2:1',
		},
		'200': {
			question: 'Jesus was baptized in this river by John the Baptist.',
			answer: 'What is the Jordan River?',
			verse: 'Matthew 3:13',
		},
		'300': {
			question: 'This sermon given by Jesus includes the Beatitudes.',
			answer: 'What is the Sermon on the Mount?',
			verse: 'Matthew 5-7',
		},
		'400': {
			question: 'To this man, Jesus said, "Get thee behind me, Satan".',
			answer: 'Who is Peter?',
			verse: 'Matthew 16:23',
		},
		'500': {
			question:
				"This event marked the beginning of Jesus' ministry, where he turned water into wine.",
			answer: 'What is the Wedding at Cana?',
			verse: 'John 2:1-11',
		},
	},
};
