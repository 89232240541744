import React, { FC } from 'react';
import { Category } from '../../types/Category';
import { Box, useTheme } from '@mui/joy';
import QuestionCell from '../QuestionCell';
import { Level } from '../../types/Level';
import { Taken } from '../Board';

export interface ColumnProps {
	label: string;
	category: Category;
	taken: Taken;
	setTaken: (taken: Taken) => void;
}

const Column: FC<ColumnProps> = ({ label, category, taken, setTaken }) => {
	const { palette, radius } = useTheme();
	return (
		<Box
			sx={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<Box
				sx={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					background: palette.primary[500],
					color: 'yellow',
					fontWeight: 'bold',
					fontSize: '1.75rem',
					textAlign: 'center',
					borderRadius: radius.sm,
					p: 2,
				}}
			>
				{label}
			</Box>
			{Object.entries(category).map(([key, question]) => {
				return (
					<QuestionCell
						key={`${label}-${key}`}
						level={parseInt(key, 10) as Level}
						question={question}
						category={label}
						taken={taken[label][key]}
						setTaken={() => {
							setTaken({
								...taken,
								[label]: {
									...taken[label],
									[key]: true,
								},
							});
						}}
					/>
				);
			})}
		</Box>
	);
};

export default Column;
