import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Question } from '../../types/Question';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, IconButton, Typography, useTheme } from '@mui/joy';
import { MdClear, MdFlip } from 'react-icons/md';
import { Level } from '../../types/Level';

export interface QuestionPopProps {
	children: (provided: {
		onClick: (question: Question, category: string, level: Level) => void;
	}) => ReactNode;
}

const QuestionPop: FC<QuestionPopProps> = ({ children }) => {
	const { palette } = useTheme();

	const [question, setQuestion] = useState<Question | null>(null);
	const [category, setCategory] = useState<string | null>(null);
	const [level, setLevel] = useState<Level | null>(null);

	const [showAnswer, setShowAnswer] = useState(false);

	const onClose = () => {
		setQuestion(null);
		setShowAnswer(false);
	};

	useEffect(() => {
		if (question) {
			setShowAnswer(false);
		}
	}, [question]);

	const toggleVariants = {
		initial: {
			opacity: 0,
			y: 100,
		},
		animate: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.25,
			},
		},
		exit: {
			opacity: 0,
			y: -100,
			transition: {
				duration: 0.25,
			},
		},
	};

	return (
		<>
			{children({
				onClick: (q, c, l) => {
					setQuestion(q);
					setCategory(c);
					setLevel(l);
				},
			})}
			<AnimatePresence>
				{question && (
					<motion.div
						style={{
							position: 'absolute',
							height: '100vh',
							width: '100vw',
							left: 0,
							zIndex: 100,
						}}
						initial={{ opacity: 0, top: '100%' }}
						animate={{ opacity: 1, top: 0 }}
						exit={{ opacity: 0, top: '100%' }}
					>
						<Box
							sx={{
								background: palette.primary[500],
								display: 'flex',
								height: '100%',
								alignItems: 'center',
								justifyContent: 'center',
								textAlign: 'center',
								position: 'relative',
							}}
						>
							<Box
								sx={{
									position: 'absolute',
									top: '100px',
									left: '72px',
								}}
							>
								<Typography
									sx={{
										color: 'yellow',
										fontSize: '2rem',
									}}
									level="h1"
								>
									{category} - ${level}
								</Typography>
							</Box>
							<IconButton
								onClick={onClose}
								variant="solid"
								color="primary"
								size="lg"
								sx={{
									position: 'absolute',
									top: '72px',
									right: '72px',
									height: '100px',
									width: '100px',
								}}
							>
								<MdClear fontSize="3rem" color="yellow" />
							</IconButton>
							<IconButton
								onClick={() => setShowAnswer(!showAnswer)}
								variant="solid"
								color="primary"
								size="lg"
								sx={{
									position: 'absolute',
									bottom: '72px',
									right: '72px',
									height: '100px',
									width: '100px',
								}}
							>
								<MdFlip fontSize="3rem" color="yellow" />
							</IconButton>
							<AnimatePresence mode="wait">
								{!showAnswer ? (
									<motion.div
										key="question"
										variants={toggleVariants}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<Typography
											sx={{
												color: 'yellow',
												maxWidth: '75vw',
												fontSize: '3rem',
											}}
											level="h1"
										>
											Question:
										</Typography>
										<Typography
											sx={{
												color: 'yellow',
												maxWidth: '75vw',
												fontSize: '5rem',
											}}
											level="h1"
										>
											{question.question}
										</Typography>
									</motion.div>
								) : (
									<motion.div
										key="answer"
										variants={toggleVariants}
										initial="initial"
										animate="animate"
										exit="exit"
									>
										<Typography
											sx={{
												color: 'yellow',
												maxWidth: '75vw',
												fontSize: '3rem',
											}}
											level="h1"
										>
											Answer:
										</Typography>
										<Typography
											sx={{
												color: 'yellow',
												maxWidth: '75vw',
												fontSize: '5rem',
											}}
											level="h1"
										>
											{question.answer}
										</Typography>
										<Typography
											sx={{
												color: 'yellow',
												maxWidth: '75vw',
												fontSize: '3rem',
											}}
											level="h1"
										>
											<em>{question.verse}</em>
										</Typography>
									</motion.div>
								)}
							</AnimatePresence>
							<Box p={6} />
						</Box>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default QuestionPop;
